const footerMenuList = [];

const socialIcons = [
  {
    id: 1,
    href: "https://twitter.com/XRDBOBBY",
    text: "twitter",
  },
  {
    id: 2,
    href: "https://t.me/BOBBYXRD",
    text: "telegram",
  },
  // {
  //   id: 3,
  //   href: "https://www.discord.com",
  //   text: "discord",
  // },
  // {
  //   id: 4,
  //   href: "https://www.instagram.com",
  //   text: "instagram",
  // },
  // {
  //   id: 5,
  //   href: "https://www.tiktok.com",
  //   text: "tiktok",
  // },
];

export { footerMenuList, socialIcons };
