import { useDispatch } from "react-redux";
import { redeemModalShow } from "../redux/counterSlice"
import { useWalletData } from "./WalletDataContext";
import { useContext, useEffect } from "react";
import LootboxResourcesContext from "./LootboxResourcesContext";

const RedeemButton = () => {
  const dispatch = useDispatch();
  const {revealedUnredeemedTickets, fetchWalletData, walletData} = useWalletData();
  const { ticketAddress } = useContext(LootboxResourcesContext)

  useEffect(() => { 
    fetchWalletData(); // Fetch data initially and whenever rdt changes
    console.log("revealedUnredeemedTickets", revealedUnredeemedTickets);
    console.log("ticketAddress", ticketAddress); // Log the ticketAddress
  }, [walletData, ticketAddress])

  if(revealedUnredeemedTickets?.address.length < 1 || !revealedUnredeemedTickets) return null;

  return (
    <>
      <button
        className="bg-gradient-to-r from-accent to-blue text-white hover:scale-105 shadow-accent-volume hover:bg-accent-dark inline-block max-w-fit rounded-full py-3 px-12 text-center font-semibold transition-all fixed bottom-0 left-0 m-8 opacity-70 hover:opacity-100 z-40"
        // onClick={() => dispatch(walletModalShow())}
        onClick={() => dispatch(redeemModalShow())}
      >
        Collect Loot
      </button>
    </>
  );
};

export default RedeemButton;