import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { WalletDataProvider } from "../components/WalletDataContext";
import { useRef, useState, useEffect } from "react";
import { LootboxResourcesProvider } from "../components/LootboxResourcesContext";
import { rdtInstance } from "../utils/radixDT";

function MyApp({ Component, pageProps }) {
  const [rdt, setRdt] = useState(null);
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  useEffect(() => {
      setRdt(rdtInstance);
  }, []);

  return (
    <>
      <Meta title="Home" />
      <Provider store={store}>
        {rdt && (
          <WalletDataProvider rdt={rdt}>
            <ThemeProvider enableSystem={true} attribute="class">
              <LootboxResourcesProvider>
                <UserContext.Provider value={{ scrollRef: scrollRef }}>
                  {pid === "/login" ? (
                    <Component {...pageProps} />
                  ) : (
                    <Layout>
                      <Component {...pageProps} />
                    </Layout>
                  )}
                </UserContext.Provider>
              </LootboxResourcesProvider>
            </ThemeProvider>
          </WalletDataProvider>
        )}
      </Provider>
    </>
  );
}

export default MyApp;