import React, { useContext } from 'react';
import { useDispatch } from "react-redux";
import { walletModalShow } from "../redux/counterSlice";
import { useWalletData } from "./WalletDataContext";
import LootboxResourcesContext from "./LootboxResourcesContext"; // Import your context
import { useEffect } from "react";

const RevealButton = () => {
  const dispatch = useDispatch();
  const { fetchWalletData, unrevealedTickets, walletData } = useWalletData();
  const { ticketAddress } = useContext(LootboxResourcesContext); // Use the context

  useEffect(() => { 
    fetchWalletData(); // Fetch data initially and whenever rdt changes
    console.log("unrevealedTickets", unrevealedTickets);
    console.log("ticketAddress", ticketAddress); // Log the ticketAddress
  }, [walletData, ticketAddress]); // Add ticketAddress as a dependency

  if(unrevealedTickets?.address.length < 1 || !unrevealedTickets) return null;

  return (
    <>
      <button
        className="bg-gradient-to-r from-accent to-blue text-white hover:scale-105 shadow-accent-volume hover:bg-accent-dark inline-block max-w-fit rounded-full py-3 px-12 text-center font-semibold transition-all fixed bottom-0 left-0 m-8 opacity-70 hover:opacity-100 z-40"
        // onClick={() => dispatch(walletModalShow())}
        onClick={() => dispatch(walletModalShow())}
      >
        Reveal Ticket
      </button>
    </>
  );
};

export default RevealButton;
