import { useSelector, useDispatch } from "react-redux";
import { buyBobbyModalHide } from "../../redux/counterSlice";


const BuyBobbyModal = () => {
    const dispatchEvent = useDispatch();
    const buyBobbyModal = useSelector((state) => state.counter.buyBobbyModal);

    return (
        <div
            className={buyBobbyModal ? "fixed top-0 z-20 w-full max-w-full h-screen fade show  max-h-screen overflow-hidden flex flex-col items-center justify-center" : "modal fade hidden"}
            onClick={() => dispatchEvent(buyBobbyModalHide())}
        >    
            <iframe
                src="https://www.caviarnine.com/embed/trade?style=c9_dark01&sell_resource=resource_rdx1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxradxrd&buy_resource=resource_rdx1t45js47zxtau85v0tlyayerzrgfpmguftlfwfr5fxzu42qtu72tnt0"
                width="467px"
                height="544px"
                frameBorder="0" 
            />
        </div>
    );
};

export default BuyBobbyModal;
