import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bidsModalHide } from "../../redux/counterSlice";
import { useWalletData } from "../WalletDataContext";
import { buyLootboxTicket, buyLootboxTicketLockFee } from "../../utils/radixDT";

import Link from "next/link";
import ArtData from "../../data/art_data";

const BidsModal = () => {
  const { bidsModal } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [ticketQty, setTicketQty] = useState(1);
  const [ticketPrice, setTicketPrice] = useState(999); // [tokenAmount, setTokenAmount  
  const [tokenAmount, setTokenAmount] = useState(ticketQty * ticketPrice);
  const [termsAgreement, setTermsAgreement] = useState(false); // [tokenAmount, setTokenAmount
  const [loading, setLoading] = useState(false);
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenIconUrl, setTokenIconUrl] = useState("https://s2.coinmarketcap.com/static/img/coins/64x64/11948.png"); // [tokenAmount, setTokenAmount

  const {walletData, accountFungibles, accountNonFungibles, fetchWalletData}  = useWalletData();

  // console log hello everytime this modal popsup
  useEffect(() => {
    if (bidsModal) {
      const price = ArtData.find((box) => box.componentAddress === sessionStorage.getItem('lootboxComponentAddress')).price || 999;
      setTicketPrice(price);
      setTokenAmount(ticketQty * price);

      const tokenResourceAddress = ArtData.find((box) => box.componentAddress === sessionStorage.getItem('lootboxComponentAddress')).tokenAddress || "";
      setTokenAddress(tokenResourceAddress);

      const tokenResourceImageUrl = ArtData.find((box) => box.componentAddress === sessionStorage.getItem('lootboxComponentAddress')).tokenIconUrl || "https://s2.coinmarketcap.com/static/img/coins/64x64/11948.png";
      setTokenIconUrl(tokenResourceImageUrl);
    }
  }, [bidsModal]);

  const handleTicketQty = (e) => {
    e.preventDefault();
    const newTicketQty = e.target.value < 0 ? 1 : e.target.value;
    
    setTicketQty(newTicketQty);
  
    const newtokenAmount = newTicketQty * ticketPrice;
    setTokenAmount(newtokenAmount);
  };

  const buyTicketsRTM = async () => {
    setLoading(true);

    if (walletData.accounts.length === 0) {
      alert("Please connect your wallet first");
      setLoading(false);
      dispatch(bidsModalHide());
      return;
    }
    if (ticketQty < 1) {
      alert("Please select at least 1 ticket");
      setLoading(false);
      return;
    }
    const accountAddress = walletData.accounts[0].address;
    try {
      console.log("buying tickets with account address", accountAddress)
      const isBobby100ClubMember = await fetch('/api/bobby-100-club', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          },
          body: JSON.stringify({ account_address: accountAddress, token_amount: tokenAmount })
          }).then(res => res.json());

      if (isBobby100ClubMember.result) {
        await buyLootboxTicketLockFee(accountAddress, tokenAmount, sessionStorage.getItem('lootboxComponentAddress'));
      } else {
        await buyLootboxTicket(accountAddress, tokenAmount, sessionStorage.getItem('lootboxComponentAddress'), tokenAddress);
      }

      await fetchWalletData();
      dispatch(bidsModalHide());

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("There was an error buying tickets", error);
    }
  }

  return (
    <div>
        {/* loading spinner */}
        {loading ? (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 p-8 bg- rounded-lg items-center justify-center flex flex-col">
            <div className="p-4 bg-accent-dark/80 flex flex-col gap-4 rounded-lg shadow items-center">

              <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-gray-900"></div>
              <div className="bg-accent-dark text-white text-xl p-4 font-extrabold tracking-wider rounded-md shadow animate-pulse">
                Check your wallet to complete the transaction
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      <div className={bidsModal ? "modal fade show block z-40" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Buy Tickets
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(bidsModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
            <div className="flex gap-2">
              {/* tickets incrementer */}
              <div className="flex gap-2">
                <div className="mb-2 flex items-center justify-between">
                  <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white ">
                    Tickets
                  </span>
                </div>
                {/* tickets input */}
                <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border w-24">
                  <input
                    className="focus:ring-accent h-12 max-w-fit flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
                    type="number"
                    placeholder="Amount"
                    value={ticketQty}
                    onChange={(e) => handleTicketQty(e)}
                  />
                </div>
              </div>
              {/* total xrd cost for tickets */}
              <div className="flex gap-2">
                <div className="mb-2 flex items-center justify-between">
                  <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                    Total
                  </span>
                </div>
                <div className="dark:border-jacarta-600 h-12 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                  <div className="border-jacarta-100 bg-jacarta-50 flex flex-1 items-center justify-center self-stretch border-r px-2 gap-4">
                    <span>
                      <img src={tokenIconUrl} className="w-12" />
                    </span>
                  </div>

                  <div className="bg-jacarta-50 border-jacarta-100 flex flex-1 justify-end self-stretch border-l dark:text-jacarta-700">
                    <span className="self-center min-w-[3rem] px-2 text-sm">{tokenAmount}</span>
                  </div>
                </div>
              </div>
            </div>


              {/* <!-- Terms --> */}
              <div className="mt-4 flex items-center space-x-2">
                <label
                  htmlFor="terms"
                  className="dark:text-jacarta-200 text-sm"
                >
                  By using this service, you agree to {"$BOBBY's"}{" "}
                  <Link href="/terms">
                   Terms of Service
                  </Link>
                </label>
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  type="button"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  // disabled={!termsAgreement}
                  onClick={buyTicketsRTM}
                >
                  Buy Tickets
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidsModal;
