const ArtData = [
	{
		id: 0,
		bigImage: 'https://bafybeidxm7ncmfrrbjk4fnog4y2kfv3tjnp4chy3gj4dwbmafxwwp5meb4.ipfs.w3s.link/olympiaGold.jpg',
		userImage: '/images/logo_white.jpg',
		name: 'Olympia Gold - Sold Out',
		title: 'Olympia Gold - Sold Out',
		componentAddress: 'component_rdx1cpujpzm9wer7s9x439guwan68npj2cep56wrsp7psfpavrye36m7mm',
		ticketAddress:'resource_rdx1ntaj4pmcfkew03lcsvma785gmkey3xa9n84s7z07cs0plk9njeh8kz',
		tokenAddress: "resource_rdx1t45js47zxtau85v0tlyayerzrgfpmguftlfwfr5fxzu42qtu72tnt0", // bobby token
		tokenIconUrl: "/images/logo.jpg",
		price: 20
	},
	{
		id: 0,
		bigImage: 'https://bafybeihwzjnqcgbmhunjxvoajqw62pzjm2pa5gar5kj54ukol7dzupgtxu.ipfs.w3s.link/HugTheEcosystem.png',
		userImage: '/images/logo_white.jpg',
		name: 'Hug the Ecosystem - Sold Out',
		title: 'Hug the Ecosystem - Sold Out',
		componentAddress: 'component_rdx1cqazvlaw6fyj8e3gq7d0f9jyq4fdvxd7rwwyhew0yxlc4v5v8u6sdh',
		ticketAddress:'resource_rdx1nffzn6jnlwftc6twx80q9madqdscz8k77qsz9m4wmfv3fptk8tyc7k',
		tokenAddress: "resource_rdx1t45js47zxtau85v0tlyayerzrgfpmguftlfwfr5fxzu42qtu72tnt0", // Bobby token
		tokenIconUrl: "/images/logo.jpg",
		price: 3
	},
	{
		id: 0,
		bigImage: 'https://bafybeibzz4tv2yhvz7mi7sxss32e5z5kowgdxof4vzcinuzglox5rzmoja.ipfs.w3s.link/bobbyLootbox4.jpg',
		userImage: '/images/logo_white.jpg',
		name: 'Coming Thoon',
		title: 'Coming Thoon',
		componentAddress: 'component_rdx1cqypfzkrqyfdrh33s69zs7maap8dqu8aewpnppqvdgxqlgw3wgq30h',
		ticketAddress:'resource_rdx1n2wakrg0l6cdfqlqpk57mu56jh3jgt65yjj89ql4prcm707t8skcet',
		tokenAddress: process.env.NEXT_PUBLIC_XRD,
		tokenIconUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/11948.png",
		price: 999999,
		
	},
];

export default ArtData;
