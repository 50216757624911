import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { walletModalhide } from "../../redux/counterSlice";
import { useWalletData } from "../WalletDataContext";
import { useEffect } from "react";
import { revealLootboxTicket, revealLootboxTicketLockFee } from "../../utils/radixDT";
import ArtData from "../../data/art_data";

const Wallet_modal = () => {
  const walletModal = useSelector((state) => state.counter.walletModal);
  const dispatch = useDispatch();
  const {walletData, unrevealedTickets, accountFungibles, accountNonFungibles, fetchWalletData}  = useWalletData();
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showRevealSuccess, setShowRevealSuccess] = useState(false);

  useEffect(() => {
    setSelectedTicketId(unrevealedTickets?.address[0]?.id);
  },[unrevealedTickets]);

  const handleRevealTicket = async () => {

    let tickAddr = ArtData.find(box => box.componentAddress === sessionStorage.getItem('lootboxComponentAddress')).ticketAddress;

    setLoading(true);
    const accountAddress = walletData.accounts[0].address;
    try {
      console.log("revealing tickets with account address", accountAddress)
      const isBobby100ClubMember = await fetch('/api/bobby-100-club', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          },
          body: JSON.stringify({ account_address: accountAddress, token_amount: 0 })
          }).then(res => res.json());

      if (isBobby100ClubMember.result) {
        await revealLootboxTicketLockFee(accountAddress, selectedTicketId, sessionStorage.getItem('lootboxComponentAddress'),tickAddr);
      } else {
        await revealLootboxTicket(walletData.accounts[0].address, selectedTicketId, sessionStorage.getItem('lootboxComponentAddress'),tickAddr);
      }

      await fetchWalletData();
      alert("Ticket revealed successfully - You can now collect your loot! See button on the bottom left of the screen");
      if (unrevealedTickets?.address?.length === 0) {
        dispatch(walletModalhide());
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  }

  return (
    <div>{}
      {/* <!-- Wallet Modal --> */}
      <div
        className={walletModal ? "block modal fade show " : "modal fade hidden"}
      >
        {/* loading spinner */}
        {loading ? (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 p-8 bg- rounded-lg items-center justify-center flex flex-col ">
            <div className="p-4 bg-accent-dark/80 flex flex-col gap-4 rounded-lg shadow items-center">

              <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-gray-900"></div>
              <div className="bg-accent-dark text-white text-xl p-4 font-extrabold tracking-wider rounded-md shadow animate-pulse">
                Check your wallet to complete the transaction
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* reveal success */}
        {showRevealSuccess ? (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 p-8 bg- rounded-lg items-center justify-center flex flex-col ">
            <h3 className="text-whit bg-accent-dark text-white text-md p-4 font-semibold tracking-wider">
              Ticket revealed successfully
            </h3>
            {/* button to close */}
            <button
              type="button"
              className="btn-close"
              onClick={() => setShowRevealSuccess(false)}
            >
              Done
            </button>
          </div>
        ) : (
          ""
        )}


        <div className="modal-dialog max-w-lg w-full container font-display text-xs">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title font-display text-sm" id="walletModalLabel">
                Reveal your ticket
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(walletModalhide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6 text-center w-full">
              <span className="text-sm font-semibold ">
                Selected Ticket: {selectedTicketId}
              </span>
              <div className="text-center dark:text-white max-h-64 overflow-auto rounded-md py-4 px-2 w-full">
                {unrevealedTickets?.address?.length > 0 ? (unrevealedTickets?.address?.map((ticket, i) => {
                  return (
                    <div key={i} className={`flex flex-col items-center justify-center space-y-4 py-8 rounded-lg my-4 bg-gradient-to-br from-accent-dark to-gray shadow-md hover:bg-accent-light transition-colors cursor-pointer ${selectedTicketId === ticket.id ? "bg-accent-light" : ""}`} onClick={() => setSelectedTicketId(ticket.id)}>
                      <div className="flex flex-col items-center justify-center space-y-4">
                        <div className="flex flex-col items-center justify-center space-y-4 text-lg">
                          Ticket: {ticket.id}
                        </div>
                      </div>
                    </div>
                  )
                  })) : ('')}
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  rel="noreferrer noopener"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  onClick={handleRevealTicket}
                >
                  Reveal Ticket
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet_modal;
