import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title} || Lootboxes</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "$BOBBY | NFT Lootboxes",
  keyword:
    "radixdlt, xrd, radix, Radix, dlt, bobby, flay hedge fund, decentralized, ledger, bitcoin, blockchain, crypto, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, nft marketplace, nft, NFT, non-fungible tokens, virtual asset, wallet",
  desc: "Radix's first lootbox. $BOBBY Lootboxes provide an exciting way for users to win and collect top Radix NFTs.",
};

export default Meta;
